<template>
    <div class="columns is-centered">
        <a class="button butt control field btn-goback" @click="backPrevious">
            <fa icon="arrow-left"/>
        </a>
        <div
            v-if="finallyPassword"
            class="column is-half-desktop is-full-touch is-narrow max-width">
            <form
                @submit.prevent="questionSuccess ? (answerSuccess ?
                changePassword() :
                fetchAnswer()) :
                fetch()">
                <div class="box p-3">
                    <div class="title has-text-centered has-text-dark is-5">
                        {{ i18n('Recover Password') }}
                    </div>
                    <div v-if="!questionSuccess" class="bloq is-size-6">
                        <label for="email">
                           {{ i18n('Enter your email address to continue') }}:
                        </label>
                        <input class="input is-info"
                               type="text" id="email" v-model="form.email" required>
                        <p class="has-text-danger is-size-7">
                            {{ errors.get('email') }}
                        </p>
                    </div>
                    <div v-if="questionSuccess && !answerSuccess">
                        <div class="bloq is-size-6">
                        <label for="userMail">
                            {{ i18n('Answer the security question for password reset') }}:
                        </label>
                    </div>
                    <div class="bloq is-size-7">
                        <label for="userMail">
                            {{ securityQuestion }}
                        </label>
                        <input class="input is-info"
                               type="text" id="answer" v-model="form.answer " required>
                        <p class="has-text-danger is-size-7">
                            {{ errors.get('email') }}
                        </p>
                        <div class="recover-password" v-if="questionSuccess && !answerSuccess">
                            <p class="bloq is-size-7 pt-1 custom-styling"
                                @click="sendTemporaryPassword">
                                {{ i18n('Password retrieval by email') }}.
                            </p>
                        </div>
                    </div>
                    </div>
                    <div v-if="answerSuccess">
                        <div class="container">
                            <div class="notification  is-size-7">
                                <h4
                                    class="subtitle is-6 has-text-grey-darker requirements">
                                    {{ i18n('Password Requirements') }}
                                </h4>
                                <p>
                                    - {{ i18n('Minimum one digit, upper and lower case letters') }}
                                </p>
                                <p>
                                    - {{ i18n('Minimum 8 characters and maximum 15') }}
                                </p>
                                <p>
                                    - {{ i18n('A special character') }} /*-+)!$&
                                </p>
                            </div>
                        </div>
                        <div class="bloq">
                            <label for="new_password">
                                {{ i18n('New Password') }}:
                            </label>
                            <input class="input is-info"
                                name="new_password"
                                type="password" id="new_password"
                                v-model="form.new_password" required>
                        </div>
                        <div class="bloq">
                            <label for="password_confirmation">
                                {{ i18n("Repeat Password") }}:
                            </label>
                            <input class="input is-info"
                                name="new_password_confirmation"
                                type="password" id="new_password_confirmation"
                                v-model="form.new_password_confirmation" required>

                            <p class="has-text-danger is-size-7 mb-1">
                                {{ errors.get('new_password') }}
                            </p>
                        </div>
                    </div>
                    <div class="bloq">
                        <button class="button is-primary is-fullwidth" type="submit">
                        {{ questionSuccess ?
                            (answerSuccess ? i18n('Change password'): i18n('Verify response'))
                            : i18n('Continue') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div
            v-else
            class="box">
            <h1 class="is-size-5">
                {{ i18n('Contraseña restablecida, por favor ingrese') }}
            </h1>
            <div class="button-container">
                <a href="/login" class="button is-primary is-fullwidth">
                    {{ i18n('Go to Login') }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapMutations, mapState } from 'vuex';
import Errors from '@enso-ui/laravel-validation';
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft);

export default {
    name: 'ResetPassword',
    components: { Fa },

    inject: ['route', 'routerErrorHandler', 'toastr', 'http', 'i18n'],
    data: () => ({
        ready: false,
        form: {
            email: '',
            answer: '',
            new_password: '',
            new_password_confirmation: '',
        },
        securityQuestion: '',
        questionSuccess: false,
        answerSuccess: false,
        errors: new Errors(),
        state: {
            successful: false,
        },
        finallyPassword: true,
    }),
    computed: {
        ...mapState(['meta']),
        ...mapGetters(['isWebview']),
    },
    methods: {
        ...mapMutations('auth', ['login']),
        ...mapMutations('layout', ['home']),
        changePassword() {
            this.http.post(this.route('auth.changePassword'), {
                email: this.form.email,
                new_password: this.form.new_password,
                new_password_confirmation: this.form.new_password_confirmation,
            }).then(({ data }) => {
                this.toastr.success(data.message);
                this.finallyPassword = false;
            })
                .catch(e => {
                    this.errors.set(e.response.data.errors);
                });

            this.errors.clear('new_password');
        },
        fetchAnswer() {
            this.http.post(this.route('auth.getAnswer'), {
                email: this.form.email,
                answer: this.form.answer,
            }).then(({ data }) => {
                if (data.message === 'Respuesta correcta') {
                    this.answerSuccess = true;
                }
            })
                .catch(e => {
                    this.errors.set(e.response.data.errors);
                });

            this.errors.clear('email');
        },
        fetch() {
            if (!this.questionSuccess) {
                this.http.post(this.route('auth.userStatus'), {
                    email: this.form.email,
                }).then(({ data }) => {
                    if (data.message === 'Usuario activo') {
                        if (data.question_and_answer.length > 0) {
                            const questionData = data.question_and_answer[0];
                            this.securityQuestion = questionData.question;
                            this.questionSuccess = data.is_active;
                        } else {
                            this.sendTemporaryPassword();
                        }
                    }
                })
                    .catch(e => {
                        this.errors.set(e.response.data.errors);
                    });

                this.errors.clear('email');
            }
        },
        toggleTooltip(show) {
            this.showTooltip = show;
        },
        sendTemporaryPassword() {
            this.http.post(this.route('auth.sendTemporaryPasswordEmail'), {
                email: this.form.email,
            }).then(({ data }) => {
                this.toastr.success(data.message);
            })
                .catch(e => {
                    this.errors.set(e.response.data.errors);
                });
        },
        backPrevious() {
            if (this.questionSuccess && !this.answerSuccess && this.finallyPassword) {
                this.questionSuccess = false;
                return;
            }

            if (this.questionSuccess && this.answerSuccess && this.finallyPassword) {
                this.answerSuccess = false;
                return;
            }

            if (this.questionSuccess && this.answerSuccess && !this.finallyPassword) {
                this.finallyPassword = false;
                this.$router.push('/login');
                return;
            }

            if (!this.questionSuccess && !this.answerSuccess && this.finallyPassword) {
                this.finallyPassword = false;
                this.$router.push('/login');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.max-width {
    max-width: 400px;
}
.bloq{
    padding-bottom: 10px;
}

.password-tooltip {
  display: none;
  position: absolute;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

input:hover + .password-tooltip {
  display: block;
}

.custom-styling {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.btn-goback{
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: $bluish;
    color: $secondary;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
}

.requirements{
    font-weight: bold;
    margin-bottom: 11px;
}

.button-container {
    margin-top: 20px;
}

.btn-goback{
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: $bluish;
    color: $secondary;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
}
</style>
